import React from 'react'
// import { Link, graphql } from 'gatsby'
import classNames from 'classnames'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Layout from 'layout/layout'
import HouseItem from 'components/HouseItem/HouseItem'
import style from 'assets/jss/styles/pages/mainPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import { graphql } from 'gatsby'
import Img from 'gatsby-image';
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import { CONSTRUCCION } from 'constants/routes';

const useStyles = makeStyles(style)

const ContainerHouses = ({ data }) => {
  const classes = useStyles()
  return (
    <Layout
      pageTitle="Casas con contenedores marítimos: modelos y precios"
      pageDescription="Las casas prefabricadas con contenedores marítimos están cada vez más de moda debido a las altas calidades que se consiguen a un precio más económico que las viviendas tradicionales, ofreciendo tiempos de entrega inferiores. ¡Entra y mira nuestros modelos y precios!"
      headerImageGatsby={data.contenedoresJson.headerImage.childImageSharp.fluid}
      bgImgStyle={{backgroundPositionY: "top"}}
      filter="transparent"
      content={<div className={classes.titleBgFilter}>
        <h1 className={classes.title}>{data.contenedoresJson.title}</h1>
        <h2 className={classes.subtitle}>{data.contenedoresJson.subtitle}</h2>
        </div>
      }
    >
      <BreadcrumbsComponent
        links={[
            {link: CONSTRUCCION, text: "Construcción de viviendas"}
        ]}
        presentPath="Construcción con contenedores"
      />

      <div dangerouslySetInnerHTML={{__html: data.contenedoresJson.content.childMarkdownRemark.html}} />

        {data.contenedoresJson.houses.map((obj, i) => {
          return (          
            <HouseItem 
              key={i}
              house={obj}
              preview
            />          
          )
        })}
          
    </Layout>
  )
}

export default ContainerHouses

export const query = graphql`
  query ContenedoresPageQuery {
    contenedoresJson {
      title
      subtitle
      headerImage {
        childImageSharp {
          fluid(maxHeight: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      houses {
        title
        text
        description
        alt
        sup
        distribution
        slug
        images {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;